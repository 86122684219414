import {defineCustomElement} from 'common/init';
import {
  AuthorizeErrorEvent,
  AuthorizeLoadedEvent,
  CustomFlowSideEffectEvent,
  ResizeIframeEvent,
  ShopActionType,
} from 'types/loginButton';
import {getAnalyticsTraceId} from 'common/utils';
import {PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT} from 'common/utils/urls';
import MessageListener from 'common/MessageListener';
import MessageSender from 'common/MessageSender';
import {I18n} from 'common/translator/i18n';
import Bugsnag from 'common/bugsnag';
import {TextStyleDto} from 'types/paymentTerms';

import {buildAuthorizeUrl} from '../loginButton/authorize';
import {DefaultComponentAnalyticsContext} from '../../constants/loginDefault';
import {ATTRIBUTE_ANALYTICS_TRACE_ID} from '../../constants/loginButton';
import {IFrameEventSource} from '../../common/MessageEventSource';
import WebComponent from '../../common/WebComponent';

export const PREQUAL_LOADING_TIMEOUT = 15000;

export enum PrequalFlowVersion {
  Unspecified = 'unspecified',
  PrequalAmount = 'prequalAmount',
}

export const ELEMENT_CLASS_NAME = 'shop-prequal-amount';

export const ATTRIBUTE_STYLES = 'styles';
export const ATTRIBUTE_ON_READY = 'onready';
export const ATTRIBUTE_ON_LOADED = 'onloaded';

export type PrequalAmountEvents =
  | AuthorizeLoadedEvent
  | AuthorizeErrorEvent
  | CustomFlowSideEffectEvent
  | ResizeIframeEvent;

interface PrequalAmountAttributes {
  [ATTRIBUTE_STYLES]?: () => TextStyleDto | undefined;
  [ATTRIBUTE_ON_READY]?: (prequalified: boolean, fontLoaded: boolean) => void;
  [ATTRIBUTE_ON_LOADED]?: () => void;
}

interface SetComponentStyleEvent {
  type: 'setcomponentstyle';
  style: TextStyleDto;
}

class PrequalAmountMessageSender extends MessageSender<SetComponentStyleEvent> {
  constructor(eventDestination: HTMLIFrameElement | Window) {
    super(eventDestination, [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT]);
  }
}

/**
 * Enclosing shadow root instances in the private variable will make it
 * inaccessible from the outside for `closed` ShadowRoot.
 */
const shadowRoots = new WeakMap<PrequalAmount, ShadowRoot>();

export class PrequalAmount extends WebComponent {
  #i18n: I18n | null = null;
  #analyticsTraceId: string;
  #state: 'loading' | 'loaded' | 'confirmed' | 'ready' | 'error' = 'loading';
  #styles: PrequalAmountAttributes[typeof ATTRIBUTE_STYLES];
  #onready: PrequalAmountAttributes[typeof ATTRIBUTE_ON_READY];
  #onloaded: PrequalAmountAttributes[typeof ATTRIBUTE_ON_LOADED];
  #iframe?: HTMLIFrameElement;
  #iframeMessageListener?: MessageListener<any>;
  #iframeMessenger?: PrequalAmountMessageSender;
  #timeoutTrackingId?: ReturnType<typeof setTimeout>;
  #confirmedState: {
    shopPayInstallmentsOnboarded: boolean;
    fontLoaded: boolean;
  } = {
    shopPayInstallmentsOnboarded: false,
    fontLoaded: false,
  };

  constructor() {
    super();
    this.#analyticsTraceId =
      this.getAttribute(ATTRIBUTE_ANALYTICS_TRACE_ID) || getAnalyticsTraceId();

    const template = document.createElement('template');
    template.innerHTML = getStyle();

    shadowRoots.set(this, this.attachShadow({mode: 'open'}));
    shadowRoots.get(this)?.appendChild(template.content.cloneNode(true));
  }

  set styles(value: PrequalAmountAttributes[typeof ATTRIBUTE_STYLES]) {
    this.#styles = value;
    this.#syncStyles();
  }

  get styles(): PrequalAmountAttributes[typeof ATTRIBUTE_STYLES] {
    return this.#styles;
  }

  set onready(value: PrequalAmountAttributes[typeof ATTRIBUTE_ON_READY]) {
    this.#onready = value;
  }

  get onready() {
    return this.#onready;
  }

  set onloaded(value: PrequalAmountAttributes[typeof ATTRIBUTE_ON_LOADED]) {
    this.#onloaded = value;
  }

  get onloaded() {
    return this.#onloaded;
  }

  async connectedCallback() {
    this.#hideContent();
    await this.#initTranslations();
    this.#insertTextContent();
    this.#initIframe();
  }

  attributeChangedCallback(
    name: keyof PrequalAmountAttributes,
    _oldValue: string,
    newValue: string | null,
  ): void {
    this.updateAttribute(name, newValue || undefined);
  }

  disconnectedCallback(): void {
    this.#destroyIframe();
    this.#hideContent();
  }

  #syncStyles() {
    if (this.#state === 'loaded' && this.#styles) {
      const stylesDto = this.#styles();
      if (stylesDto) {
        this.#iframeMessenger?.postMessage({
          type: 'setcomponentstyle',
          style: stylesDto,
        });
      }
    }
  }

  handlePostMessage(event: PrequalAmountEvents) {
    this.#clearWaitingTimeout();

    /**
     * This component reacts on events as a State Machine
     */
    switch (event.type) {
      case 'custom_flow_side_effect':
        this.#state = 'confirmed';
        this.#confirmedState = {
          shopPayInstallmentsOnboarded: Boolean(
            event.shopPayInstallmentsOnboarded,
          ),
          fontLoaded: Boolean(event.fontAssetLoaded),
        };
        this.#showContent();
        break;
      case 'error':
        this.#reportErrorState();
        break;
      case 'loaded':
        if (this.#state === 'loading') {
          this.#state = 'loaded';
          this.#onloaded?.();
          this.#syncStyles();
        }
        break;
      case 'resize_iframe':
        this.#iframe!.style.height = `${event.height}px`;
        this.#iframe!.style.width = `${event.width}px`;
        if (this.#state === 'confirmed') {
          this.#reportReadyState(
            this.#confirmedState.shopPayInstallmentsOnboarded,
            this.#confirmedState.fontLoaded,
          );
        }
        break;
    }
  }

  #clearWaitingTimeout() {
    if (this.#timeoutTrackingId) {
      clearTimeout(this.#timeoutTrackingId);
      this.#timeoutTrackingId = undefined;
    }
  }

  #reportErrorState() {
    this.#state = 'error';
    this.#hideContent();
    this.#onready?.(false, false);
  }

  #reportReadyState(
    shopPayInstallmentsOnboarded: boolean,
    fontLoaded?: boolean,
  ) {
    if (this.#state === 'error') {
      return;
    }

    this.#state = 'ready';
    if (shopPayInstallmentsOnboarded) {
      this.#showContent();
    } else {
      this.#hideContent();
    }

    this.#onready?.(shopPayInstallmentsOnboarded, Boolean(fontLoaded));
  }

  async #initTranslations() {
    if (this.#i18n) return;
    try {
      const locale = I18n.getDefaultLanguage();
      const dictionary = await import(`./translations/${locale}.json`);
      this.#i18n = new I18n({[locale]: dictionary});
    } catch (error) {
      if (error instanceof Error) {
        Bugsnag.notify(error);
      }
    }
  }

  #showContent() {
    this.style.display = '';
  }

  #hideContent() {
    this.style.display = 'none';
  }

  #insertTextContent() {
    if (!this.#i18n) {
      // error?
      return;
    }

    const container = shadowRoots
      .get(this)
      ?.querySelector('.container') as HTMLSpanElement;

    const text = this.#i18n.translate(
      'banner.prequal_contents.purchasing_power_b',
    );

    const textContent = document.createTextNode(text);
    const span = document.createElement('span');
    span.appendChild(textContent);

    container.innerHTML = '';
    container.appendChild(span);
  }

  #buildIframeSrc() {
    return buildAuthorizeUrl({
      version: '2',
      analyticsTraceId: this.#analyticsTraceId,
      analyticsContext: DefaultComponentAnalyticsContext.Prequal,
      isCompactLayout: false,
      flow: ShopActionType.Prequal,
      flowVersion: PrequalFlowVersion.PrequalAmount,
      emailVerificationRequired: false,
      signUpEnabled: false,
      avoidPayAltDomain: false,
      avoidSdkSession: false,
      hideCopy: true,
      requireVerification: false,
    });
  }

  #initIframe() {
    this.#iframe = document.createElement('iframe');

    this.#timeoutTrackingId = setTimeout(() => {
      this.#reportErrorState();
    }, PREQUAL_LOADING_TIMEOUT);

    this.#iframe.src = this.#buildIframeSrc();

    this.shadowRoot?.querySelector('.container')?.appendChild(this.#iframe);

    if (!this.#iframeMessageListener) {
      this.#iframeMessageListener = new MessageListener<PrequalAmountEvents>(
        new IFrameEventSource(this.#iframe),
        [PAY_AUTH_DOMAIN, PAY_AUTH_DOMAIN_ALT, window.location.origin],
        this.handlePostMessage.bind(this),
        this.ownerDocument?.defaultView || undefined,
      );
    }

    if (!this.#iframeMessenger) {
      this.#iframeMessenger = new PrequalAmountMessageSender(this.#iframe);
    }
  }

  #destroyIframe() {
    this.#iframeMessageListener?.destroy();
  }
}
/**
 * function which returns the component's style
 * @returns {string} the style
 */
function getStyle(): string {
  return `
    <style>

      .container {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        column-gap: 4px;
      }

      .container iframe {
        border: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        /* prevents flickering before content loaded */
        width: 0;
        height: 0;
      }

      .container::after {
        content: '.';
        display: inline-block;
        margin-left: -4px;
      }
    </style>

    <div class="container">
    </div>
  `;
}

/**
 *
 */
export function defineElement() {
  defineCustomElement('shop-prequal-amount', PrequalAmount);
}
