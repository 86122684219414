import MonorailTracker, {
  produceMonorailEvent,
} from 'common/analytics/MonorailTracker';
import {getTrekkieAttributes} from 'common/analytics/trekkie';
import {APP_VERSION} from 'common/constants';
import {MonorailSchema} from 'common/analytics/types';
import {
  BannerTemplateCodeSignature,
  InstallmentsBannerType,
  InstallmentsBannerContent,
  ModalUserAction,
  ModalType,
} from 'types';
import {InstallmentsPrequalPageType} from 'types/paymentTerms';

export class MonorailTrackerPaymentTerms extends MonorailTracker {
  private _modalActionTracker: Record<string, boolean> = {};
  private _bannerImpressionTracked: Record<string, boolean> = {};
  private _prequalPopupPageImpressionTracked: Record<string, boolean> = {};
  private _bannerPrequalInteractionTracked = false;
  private _invalidBannerMetadataTracked = false;

  /**
   * @param {object} params The parameters object.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * @param {string} params.flow The SDK flow, eg. ('discount' or 'follow').
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up")
   * @param {number} [params.shopId] The numeric id of the shop.
   * @param {string} [params.checkoutVersion] A checkout version such as "classic" or "shop_pay_external"
   */
  constructor({
    elementName,
    analyticsTraceId,
    flow = '',
    flowVersion = 'unspecified',
    shopId,
    checkoutVersion,
  }: {
    elementName: string;
    analyticsTraceId?: string;
    flow?: string;
    flowVersion?: string;
    shopId?: number;
    checkoutVersion?: string;
  }) {
    super({
      elementName,
      analyticsTraceId,
      flow,
      flowVersion,
      shopId,
      checkoutVersion,
    });
  }

  /**
   * Fired when the shopify-installments-modal component from shop-js has opened for the Cart or
   * a Product Variant.
   * @param {InstallmentsBannerType} origin The origin page type of this modal.
   * @param {string} modalToken A token generated by the modal to uniquely identify the modal and actions taken by the user.
   * @param {ModalType} eligibleSpiPlanType The type of modal that emitted the event.
   * @param {string} spiPlanDetails A stringified JSON array with details about sample installments plans shown on the modal.
   * @param {number} variantId A Unique identifier for a product variant. Is supported for Product Variant pages.
   * @param {string} price The price that is shown on the installments modal. Represents the total price of all
   *   items in the cart if the impression is from the `cart` page and the price of the product variant if the impression
   *   is from a `product` page.
   * @param {string} cartPermalink The permalink used to create the cart if the modal has a 'Continue to Checkout' button available.
   */
  async trackModalOpened(
    origin: InstallmentsBannerType,
    modalToken: string,
    eligibleSpiPlanType: ModalType,
    spiPlanDetails: string,
    variantId?: number,
    price?: string,
    cartPermalink?: string,
  ): Promise<void> {
    let modalActionIdentifier: string;

    if (origin === InstallmentsBannerType.Cart) {
      modalActionIdentifier = `${origin}-open`;
    } else {
      modalActionIdentifier = `${modalToken}-open`;
    }

    if (this._modalActionTracker[modalActionIdentifier]) {
      return;
    }
    this._modalActionTracker[modalActionIdentifier] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
      'currency',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      modalToken,
      eligibleSpiPlanType,
      price,
      cartPermalink,
      spiPlanDetails,
      variantId,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsModalOpened,
        payload,
      },
      trekkieAttributes,
      () => {
        this._modalActionTracker[modalActionIdentifier] = false;
      },
    );
  }

  /**
   * Fired when a user makes a meaningful action on the shopify-installments-modal.
   * @param {string} modalToken A token generated by the modal to uniquely identify the modal and actions taken by the user.
   * @param {ModalUserAction} action The action taken by the user.
   * @param {string} cartPermalink The permalink used to create the cart if the modal has a 'Continue to Checkout' button available.
   */
  async trackModalAction(
    modalToken: string,
    action: ModalUserAction,
    cartPermalink?: string,
  ): Promise<void> {
    const modalActionIdentifier = `${modalToken}-${action}`;
    if (this._modalActionTracker[modalActionIdentifier]) {
      return;
    }
    this._modalActionTracker[modalActionIdentifier] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
    );

    const payload = {
      ...trekkieAttributes,
      modalToken,
      action,
      cartPermalink,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsModalUserAction,
        payload,
      },
      trekkieAttributes,
      () => {
        this._modalActionTracker[modalActionIdentifier] = false;
      },
    );
  }

  /**
   * Fired when the installment banner component is mounted on the page.
   * @param {InstallmentsBannerType} origin The origin page type of this banner.
   * @param {InstallmentsBannerContent} bannerContent The content of the banner that was shown to the user.
   * @param {boolean} eligible Whether the user's product or cart is currently eligible for installments based on price.
   * @param {BannerTemplateCodeSignature} bannerTemplateCodeSignature The banner implementation being used on the page.
   * @param {boolean} hasPrequalLink Whether the banner includes prequal flow. True if banner includes CTA link
    to start prequal flow
   * @param {string} price The price that is shown on the installments modal. Represents the total price of all
   *   items in the cart if the impression is from the `cart` page and the price of the product variant if the impression
   *   is from a `product` page.
   * @param {number} variantId A Unique identifier for a product variant. Is supported for Product Variant pages exclusively.
   */
  async trackInstallmentsBannerImpression(
    origin: InstallmentsBannerType,
    bannerContent: InstallmentsBannerContent,
    eligible: boolean,
    bannerTemplateCodeSignature: BannerTemplateCodeSignature,
    hasPrequalLink: boolean,
    price?: string,
    variantId?: number,
  ): Promise<void> {
    const identifier = variantId ? String(variantId) : 'cart';
    if (this._bannerImpressionTracked[identifier]) {
      return;
    }
    this._bannerImpressionTracked[identifier] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'shopId',
      'microSessionId',
      'contentLanguage',
      'currency',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      bannerContent,
      eligible,
      bannerTemplateCodeSignature,
      price,
      shopJsVersion: APP_VERSION,
      hasPrequalLink,
      // The event triggered in ShopPayBanner won't contain an analyticsTraceId.
      // Because this ID is only relevant in the prequalification flow,
      // to ensure schema validation, we assign it an empty string in this context."
      analyticsTraceId: this.analyticsTraceId! || '',
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsBannerImpression,
        payload,
      },
      trekkieAttributes,
      () => {
        this._bannerImpressionTracked[identifier] = false;
      },
    );
  }

  /**
   * Fired whenever the buyer clicks the prequal link in the banner on the page, To track click of the prequal link in the banner on the product page
   * @param {number} sellerId the Installments identifier for the shop
   * @param {string} pageType The page that the buyer reached.
   */
  async trackInstallmentsPrequalPopupPageImpression(
    sellerId: number | undefined,
    pageType: InstallmentsPrequalPageType,
  ): Promise<void> {
    if (this._prequalPopupPageImpressionTracked[pageType]) {
      return;
    }
    this._prequalPopupPageImpressionTracked[pageType] = true;

    const payload = {
      analyticsTraceId: this.analyticsTraceId!,
      sellerId,
      pageType,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsPrequalPopupPageImpression,
        payload,
      },
      undefined,
      () => {
        this._prequalPopupPageImpressionTracked[pageType] = false;
      },
    );
  }

  /**
   * Fired when the installment banner component is mounted on the page and the metadata being supplied is missing a required attribute.
   * @param {InstallmentsBannerType} origin The type element that emitted the event.
   * @param {string} metadata The data currently passed into the installment banner as the shopify-meta attribute.
   */
  async trackInvalidInstallmentBannerMetadata(
    origin: InstallmentsBannerType,
    metadata: string,
  ): Promise<void> {
    if (this._invalidBannerMetadataTracked) {
      return;
    }
    this._invalidBannerMetadataTracked = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      metadata,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsInvalidMetadata,
        payload,
      },
      trekkieAttributes,
      () => {
        this._invalidBannerMetadataTracked = false;
      },
    );
  }

  /**
   * Fired whenever the buyer clicks the prequal link in the banner on the page, To track click of the prequal link in the banner on the product page
   * @param {InstallmentsBannerType} origin The type element that emitted the event.
   * @param {InstallmentsBannerContent} bannerContent The content of the banner that was shown to the user.
   * @param {boolean} eligible Whether the user's product or cart is currently eligible for installments based on price.
   * @param {string} price The price that is shown on the installments modal. Represents the total price of all
   * @param {boolean} prequalLinkClicked  True if the user clicked the prequal link
   */
  async trackInstallmentsBannerPrequalInteraction(
    origin: InstallmentsBannerType,
    bannerContent: InstallmentsBannerContent,
    eligible: boolean,
    price: string,
    prequalLinkClicked: boolean,
  ): Promise<void> {
    if (this._bannerPrequalInteractionTracked) {
      return;
    }
    this._bannerPrequalInteractionTracked = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'shopId',
      'microSessionId',
      'contentLanguage',
      'currency',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      bannerContent,
      eligible,
      price,
      shopJsVersion: APP_VERSION,
      prequalLinkClicked,
      analyticsTraceId: this.analyticsTraceId!,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsBannerPrequalInteraction,
        payload,
      },
      trekkieAttributes,
      () => {
        this._bannerPrequalInteractionTracked = false;
      },
    );
  }
}
